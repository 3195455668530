
import * as __star__ from 'react-dom';

const {
default: __default__,
__SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED,
createPortal,
createRoot,
findDOMNode,
flushSync,
hydrate,
hydrateRoot,
render,
unmountComponentAtNode,
unstable_batchedUpdates,
unstable_renderSubtreeIntoContainer,
version
} = __star__;

export {
__SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED,
createPortal,
createRoot,
findDOMNode,
flushSync,
hydrate,
hydrateRoot,
render,
unmountComponentAtNode,
unstable_batchedUpdates,
unstable_renderSubtreeIntoContainer,
version
};
export default __default__;
